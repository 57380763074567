<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <van-form
          @submit="onSubmit"
          label-width="4.5em"
      >
        <div class="form-card">
          <van-field
              v-model.trim="form.name"
              name="name"
              label="收货人"
              placeholder="收货人"
              :rules="[{ required: true, message: '请填写收货人' }]"
          />
          <van-field
              v-model.trim="form.mobile"
              name="mobile"
              label="手机号码"
              placeholder="手机号码"
              :rules="[{ required: true, message: '请填写手机号码' }, {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: '请输入正确的手机号'}]"
          />
          <address-selector class="address-selector-item" ref="addressSelector" v-model="addressInfo">
            <template #default="{ valueText }">
              <van-field
                  :value="valueText"
                  name="street"
                  type="textarea"
                  rows="1"
                  :autosize="true"
                  is-link
                  readonly
                  label="所在地区"
                  placeholder="请选择所在地区"
                  @click="$refs.addressSelector.show = true"
                  :rules="[{ required: true, message: '请选择所在地区' }]"
              />
            </template>
          </address-selector>
          <van-field
              v-model.trim="form.address"
              name="address"
              type="textarea"
              autosize
              label="详细地址"
              placeholder="详细地址"
              :rules="[{ required: true, message: '请填写详细地址' }]"
          />
        </div>

        <div class="form-card">
          <van-field
              name="defaultAddress"
              label="设为默认收货地址"
              label-width="8.5em"
              input-align="right"
          >
            <template #input>
              <van-switch
                  v-model="form.defaultAddress"
                  :active-value="0"
                  :inactive-value="1"
                  size="20"
              />
            </template>
          </van-field>
        </div>

        <div class="submit-container">
          <van-button round block type="primary" native-type="submit">保存</van-button>
        </div>
      </van-form>
    </page-container>
  </div>
</template>

<script>
import { queryUserAddressById, addAddress, editAddress } from '@/api/address';
import AddressSelector from '@/components/AddressSelector';
import { GET_USER_ID } from '@/util/storage';

const defaultForm = () => ({
  // id: '',

  provinceCode: '',
  cityCode: '',
  countryCode: '',
  streetCode: '',
  provice: '',
  city: '',
  country: '',
  street: '',

  address: '',
  defaultAddress: 1,
  userId: '',
  name: '',
  mobile: '',
});

export default {
  name: 'modifyAddress',
  components: { AddressSelector },
  data() {
    return {
      form: defaultForm(),
    };
  },
  computed: {
    addressInfo: {
      get() {
        let {
          provinceCode = '',
          cityCode = '',
          countryCode = '',
          streetCode = '',
          provice = '',
          city = '',
          country = '',
          street = '',
        } = this.form;
        return [
          { id: provinceCode, name: provice },
          { id: cityCode, name: city },
          { id: countryCode, name: country },
          { id: streetCode, name: street },
        ];
      },
      set(val) {
        let [
          { id: provinceCode = '', name: provice = '' } = {},
          { id: cityCode = '', name: city = '' } = {},
          { id: countryCode = '', name: country = '' } = {},
          { id: streetCode = '', name: street = '' } = {},
        ] = Array.isArray(val) ? val : [];
        Object.assign(this.form, {
          provinceCode,
          cityCode,
          countryCode,
          streetCode,
          provice,
          city,
          country,
          street,
        });
      },
    },
  },
  created() {
    this.form.userId = GET_USER_ID();
    let id = this.$route.query.addressId;
    if (id) {
      this.form.id = id;
      queryUserAddressById({ id }).then(res => {
        res = res || {};
        this.form = {
          id,
          ...defaultForm(),
          ...res,
        };
      });
    }
  },
  methods: {
    onSubmit() {
      let service = this.form.id ? editAddress : addAddress;
      service({ ...this.form }).then(res => {
        if (res === true) {
          this.$store.commit('receiverAddress/setRefreshing', true);
          this.$store.commit('payment/setAddressId');
          this.$router.back();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.form-card {
  box-sizing: border-box;
  margin: 10px;
  padding: 5px 0;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
}

.submit-container {
  padding: 10px 20px 20px 20px;
}

.address-selector-item {
  position: relative;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
    pointer-events: none;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
  }
}
</style>
